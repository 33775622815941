@use './mixins.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather:700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:500&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


html, body, #app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  // font-family: 'Roboto, Sans-serif';
  font-family: 'Roboto';
  // font-family: 'Segoe UI';
}

* {
  box-sizing: border-box;
}
